<template>
    <section class="connect" v-if="data">
        <!-- <div id="slider"></div> -->
        <div class="lockout">

            <div class="connect__top">
                <div class="connect__top__left-image">
                    <!-- <img :src="data.top.image" alt=""> -->
                    <svg role = "img" aria-label = "A decorative frame border." class="connectTop" enable-background="new 0 0 752.87 747.17" viewBox="0 0 752.87 747.17" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <clipPath id="a">
                            <path d="m521.33 226.12h-501.21c0 501.2 501.2 494.69 501.2 494.69v-494.69" />
                        </clipPath>
                        <g clip-path="url(#a)">
                            <image class="connectTopSvgLinkedImage" height="800" overflow="visible" transform="matrix(.6238 0 0 .6184 21.9052 226.0838)" width="800" :xlink:href="data.top.svgLinkedImage.src"><desc>{{ data.top.svgLinkedImage.alt }}</desc></image>
                        </g>
                        <path class="connectTopSvgFill" d="m311.6 226.09h209.37c0-209.37-209.37-206.65-209.37-206.65z" fill="#dfd9c8" />
                        <g style="fill:none;stroke:#232221;stroke-width:6;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">

                            <!-- red -->
                            <path class="top-vertical" d="m311.6 19.43v207" stroke="#b22028" />
                            <path class="top-arc" d="m521.33 226.43c0-209.72-209.72-207-209.72-207" stroke="#b22028" />
                            <path class="right-horizontal" d="m731.56 433.09h-209.73" stroke="#b22028" />
                            <path class="right-arc" d="m521.83 226.09s209.72-2.72 209.72 207" stroke="#b22028" />
                            <path class="middle-arc" d="m20.12 226.12c0 501.2 501.2 494.69 501.2 494.69" stroke="#b22028" />
                            <path class="middle-vertical" d="m521.33 720.81v-494.69" stroke="#b22028" />
                            <path class="middle-horizontal" d="m521.33 226.12h-501.21" stroke="#b22028" />

                            <!-- tan -->
                            <path class="top-vertical" d="m311.6 19.43v207" stroke="#d6d0b4" />
                            <path class="top-arc" d="m521.33 226.43c0-209.72-209.72-207-209.72-207" stroke="#d6d0b4" />
                            <path class="right-horizontal" d="m731.56 433.09h-209.73" stroke="#d6d0b4" />
                            <path class="right-arc" d="m521.83 226.09s209.72-2.72 209.72 207" stroke="#d6d0b4" />
                            <path class="middle-arc" d="m20.12 226.12c0 501.2 501.2 494.69 501.2 494.69" stroke="#d6d0b4" />
                            <path class="middle-vertical" d="m521.33 720.81v-494.69" stroke="#d6d0b4" />
                            <path class="middle-horizontal" d="m521.33 226.12h-501.21" stroke="#d6d0b4" />

                            <!-- black -->
                            <path class="top-vertical" d="m311.6 19.43v207" stroke="#1c1b19" />
                            <path class="top-arc" d="m521.33 226.43c0-209.72-209.72-207-209.72-207" stroke="#1c1b19" />
                            <path class="right-horizontal" d="m731.56 433.09h-209.73" stroke="#1c1b19" />
                            <path class="right-arc" d="m521.83 226.09s209.72-2.72 209.72 207" stroke="#1c1b19" />
                            <path class="middle-arc" d="m20.12 226.12c0 501.2 501.2 494.69 501.2 494.69" stroke="#1c1b19" />
                            <path class="middle-vertical" d="m521.33 720.81v-494.69" stroke="#1c1b19" />
                            <path class="middle-horizontal" d="m521.33 226.12h-501.21" stroke="#1c1b19" />

                        </g>
                    </svg>
                </div>

                <div class="connect__top__copy">
                    <img src="/img/global/sms-logo--mark.svg" alt="Santa Monica Studios logo mark" class="connect__top__copy__logo--mark">
                    <h1 class="connect__top__copy__header">{{ data.top.header }}</h1>
                    <h2 class="connect__top__copy__subheading">{{ data.top.subheading }}</h2>
                    <div class="connect__top__copy__description" v-html="data.top.description"></div>
                </div>
            </div>

            <div class="connect__link-grid" v-scroll-reveal="{ distance: '0px', beforeReveal: revealConnectGrid }">
                <a class="connect__link-grid__cell facebook" :href="data.links.fb" target="_blank">
                    <svg role = "img" aria-label = "Facebook icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.43 93.32">
                        <path class="cls-1" fill="#b22028" d="M41.81,46.66H28.64V93.32H9.24V46.66H0V30.26H9.24V19.63C9.24,12,12.94,0,28.87,0H43.2V15.94H32.8a4,4,0,0,0-4.16,4.39V30H43.43Z" /></svg>
                    <p>Facebook</p>
                </a>
                <a class="connect__link-grid__cell instagram" :href="data.links.ig" target="_blank">
                    <svg role = "img" aria-label = "Instagram icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.98 86">
                        <path class="cls-1" fill="#b22028" d="M43,0c6.18,0,12.36,0,18.53,0A39.65,39.65,0,0,1,67,.56a16.6,16.6,0,0,1,2.7.79c.65.21,1.29.42,1.92.67a13.69,13.69,0,0,1,1.34.64c.56.3,1.13.59,1.66.94a25.74,25.74,0,0,1,2.27,1.6A28,28,0,0,1,79,7.13a18,18,0,0,1,1.42,1.6c.65.83,1.32,1.66,1.89,2.55a25.54,25.54,0,0,1,1.24,2.38c.26.53.5,1.07.76,1.6,0,.06.06.1.08.16.22.78.4,1.57.68,2.33a19.76,19.76,0,0,1,.7,4.17,17.66,17.66,0,0,1,.2,2.4q0,18.95,0,37.89a35.51,35.51,0,0,1-.41,3.95,14,14,0,0,1-.89,3.44c-.24.64-.43,1.29-.68,1.92s-.46,1-.71,1.49-.52,1-.82,1.49a13.93,13.93,0,0,1-1.13,1.7c-.82,1-1.66,2-2.58,2.9A18.66,18.66,0,0,1,75.07,82c-1.1.63-2.2,1.28-3.33,1.86a11.91,11.91,0,0,1-1.79.65c-.88.28-1.76.59-2.66.81-.67.16-1.39.16-2.07.3A32.89,32.89,0,0,1,58.5,86H27.88a40,40,0,0,1-8.11-.44c-.51-.1-1-.22-1.53-.34-.25,0-.51-.1-.77-.17s-.68-.21-1-.33c-.11,0-.21-.09-.33-.12a14.82,14.82,0,0,1-3.37-1.46,28.1,28.1,0,0,1-2.89-1.75,33,33,0,0,1-3.14-2.76,17.87,17.87,0,0,1-2-2.38C4,75.35,3.5,74.38,3,73.43a17.35,17.35,0,0,1-.81-1.54c-.26-.58-.48-1.18-.68-1.78-.3-.92-.62-1.84-.85-2.78-.16-.64-.14-1.33-.28-2A28.13,28.13,0,0,1,0,59.41q0-18,0-36.09A22.36,22.36,0,0,1,.24,21c.06-.49.11-1,.2-1.47s.2-.88.3-1.32c0-.12.09-.24.12-.37.22-.8.4-1.6.65-2.39a7.94,7.94,0,0,1,.58-1.3,32.16,32.16,0,0,1,1.5-2.85c.66-1,1.44-2,2.19-2.91A18.26,18.26,0,0,1,9.43,5c.75-.55,1.54-1.06,2.34-1.55s1.56-.9,2.36-1.29A13.34,13.34,0,0,1,16,1.45c.87-.28,1.75-.52,2.63-.78A1.57,1.57,0,0,1,19,.54C20.71.42,22.39,0,24.13,0,30.42,0,36.71,0,43,0ZM19.07,42.59A23.91,23.91,0,1,0,43,19.09,23.85,23.85,0,0,0,19.07,42.59Zm57.29-28.2a3.75,3.75,0,0,0-1.09-3.11,3.78,3.78,0,0,0-2.36-1.54,5.37,5.37,0,0,0-3.45.35,4.88,4.88,0,0,0-2.63,3.67,4.63,4.63,0,0,0,1.55,4.11,4.68,4.68,0,0,0,6.77-.24,3.81,3.81,0,0,0,1.21-2.89C76.35,14.62,76.36,14.51,76.36,14.39Z" />
                        <path class="cls-1" fill="#b22028" d="M43,28.75A14.26,14.26,0,1,1,28.72,42.5,14.12,14.12,0,0,1,43,28.75Z" /></svg>
                    <p>Instagram</p>
                </a>
                <a class="connect__link-grid__cell twitter" :href="data.links.tw" target="_blank">
                    <svg role = "img" aria-label = "Twitter icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.43 73.32">
                        <path class="cls-1" fill="#b22028" d="M81.23,18.17v2.35c0,24.59-18.59,52.8-52.8,52.8A51.63,51.63,0,0,1,0,65a32.14,32.14,0,0,0,4.49.21,37.69,37.69,0,0,0,23.09-7.91A18.7,18.7,0,0,1,10.26,44.46a18.18,18.18,0,0,0,3.42.43,18.17,18.17,0,0,0,4.92-.64,18.57,18.57,0,0,1-15-18.17v-.21A19,19,0,0,0,12,28.22,18.84,18.84,0,0,1,3.63,12.83,17.78,17.78,0,0,1,6.2,3.42,52.8,52.8,0,0,0,44.46,22.87,26.75,26.75,0,0,1,44,18.6,18.6,18.6,0,0,1,76.1,5.77,37,37,0,0,0,87.86,1.28a19.34,19.34,0,0,1-8.12,10.26,35.75,35.75,0,0,0,10.69-3A35.78,35.78,0,0,1,81.23,18.17Z" /></svg>
                    <p>Twitter</p>
                </a>

                <a class="connect__link-grid__cell linkedin" :href="data.links.li" target="_blank">
                    <svg role = "img" aria-label = "LinkedIn icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 83.19 83.19">
                        <path class="cls-1" fill="#b22028" d="M76,83.19H7.15A2.28,2.28,0,0,0,6.79,83a8.48,8.48,0,0,1-4.9-2.94A10.43,10.43,0,0,1,0,76V7.15a1,1,0,0,0,.14-.28A8.35,8.35,0,0,1,8.58,0h66a9.48,9.48,0,0,1,2.25.26,8.37,8.37,0,0,1,6.32,8.39V74.53c0,.25,0,.49,0,.73a8.37,8.37,0,0,1-2.83,5.83A10,10,0,0,1,76,83.19ZM70.67,70.67c0-.24,0-.4,0-.56,0-7.69,0-15.38,0-23.07A14,14,0,0,0,65.48,36c-4.45-3.77-10.71-5-16.25-1.12a39.22,39.22,0,0,0-3.45,3.05V33.36H33.36v37.3H45.77v-1q0-10.06,0-20.14a14.53,14.53,0,0,1,.07-1.62,6.26,6.26,0,0,1,7.81-5.19,6.34,6.34,0,0,1,4.57,6.44q0,10.27,0,20.55v1ZM12.55,33.32V70.64H24.91V33.32Zm13.63-14.6a7.48,7.48,0,1,0-7.49,7.46A7.5,7.5,0,0,0,26.18,18.72Z" /></svg>
                    <p>LinkedIn</p>
                </a>

                <a class="connect__link-grid__cell email" :href="data.links.email" target="_blank">
                    <svg role = "img" aria-label = "Email icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.02 28.01">
                        <path class="cls-1" fill="#b22028" d="M38,28H0V0H38ZM3.47,24.54H34.55V3.47H3.47Z" />
                        <polygon class="cls-1" fill="#b22028" points="19.01 20.84 0.39 3.15 3.08 0.32 19.01 15.45 34.94 0.32 37.63 3.15 19.01 20.84" /></svg>
                    <p>Email</p>
                </a>

                <a class="connect__link-grid__cell featured" :href="data.featuredTweet.url" target="_blank">
                    <div class="top">
                        <div class="dots left">
                            <img src="../assets/connect/featured-tweet-left-line-flush.svg" alt = "">
                        </div>
                        <div class="top__title">
                            <p>featured tweet</p>
                        </div>

                        <div class="dots right">
                            <img src="../assets/connect/featured-tweet-right-line-flush.svg" alt = "">
                        </div>

                    </div>
                    <div class="handle">{{ data.featuredTweet.handle }}</div>
                    <div class="tweet-text" v-html="data.featuredTweet.tweet"></div>
                </a>

                <!-- <a class="connect__link-grid__cell directions-detail" :href="data.addressLink" target="_blank" v-html="data.address">
                </a> -->


                <div class="connect__link-grid__cell design">
                    <!-- <img src="/img/careers/careers__grid-r.svg" alt=""> -->
                    <svg role = "img" aria-label = "A decorative frame border." xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 280">
                        <path class="cls-1" style="fill:#dfd9c8;stroke:#232221;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px;" d="M140.93,141.46h0a0,0,0,0,1,0,0V280.38a0,0,0,0,1,0,0H2a0,0,0,0,1,0,0v0A138.93,138.93,0,0,1,140.93,141.46Z" />
                        <path class="cls-2" style="fill:none;stroke:#232221;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px;" d="M279.85,2h0a0,0,0,0,1,0,0V140.93a0,0,0,0,1,0,0H140.93a0,0,0,0,1,0,0v0A138.93,138.93,0,0,1,279.85,2Z" transform="translate(420.78 142.93) rotate(180)" /></svg>
                </div>
            </div>

            <div class="connect__rise">
                <div class="connect__rise__margin"></div>
                <div class="connect__rise__content">
                    <div class="connect__rise__subheading">{{ data.bottom.subheading }}</div>
                    <div class="connect__rise__header" v-scroll-reveal="{ distance: '0px', beforeReveal: revealRise }">{{ data.bottom.header }}</div>



                    <svg role = "img" aria-label = "A decorative frame border." version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 364.46 460.9" style="enable-background:new 0 0 364.46 460.9;" xml:space="preserve">
                        <g>
                            <defs>
                                <path id="connect-bottom-SVGID_1_" d="M353.83,9.25c-40.76,0-79.85,7.15-116.09,20.26C106.44,77,12.62,202.77,12.62,350.46h341.21V9.25z" />
                            </defs>
                            <clipPath id="connect-bottom-SVGID_2_">
                                <use xlink:href="#connect-bottom-SVGID_1_" style="overflow:visible;" />
                            </clipPath>
                            <g style="clip-path:url(#connect-bottom-SVGID_2_);">

                                <image class="connect-bottom-img" style="overflow:visible;" width="800" height="800" id="nZS3Ec" :xlink:href="data.bottom.connectRiseImg.src" transform="matrix(0.426 0 0 0.426 12.6167 9.2474)"><desc>{{ data.bottom.connectRiseImg.alt }}</desc></image>
                            </g>
                        </g>
                        <path class="connect-bottom-OVERLAP-Shape-WHITE-FILL" style="fill:#FFFFFF;" d="M353.7,350.22v-98.37c-98.37,0-97.09,98.37-97.09,98.37H353.7" />


                        <!-- red -->
                        <path class="connect-bottom-OVERLAP-Shape-STROKE-Curve" style="fill:#FFFFFF;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M353.7,251.85c-98.37,0-97.09,98.37-97.09,98.37" />
                        <path class="connect-bottom-Bottom-Shape-Curve" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M159.57,448.78c98.32,0,97.04-98.32,97.04-98.32" />
                        <line class="connect-bottom-Bottom-Shape-Veritcal" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="159.57" y1="350.46" x2="159.57" y2="448.78" />
                        <path class="connect-bottom-TopShape-Curve" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M353.83,9.25c-40.76,0-79.85,7.15-116.09,20.26C106.44,77,12.62,202.77,12.62,350.46" />
                        <line class="connect-bottom-TopShape-Horiz" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="12.62" y1="350.46" x2="353.83" y2="350.46" />
                        <line class="connect-bottom-TopShape-Vert" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="353.83" y1="350.46" x2="353.83" y2="9.25" />

                        <!-- tan -->
                        <path class="connect-bottom-OVERLAP-Shape-STROKE-Curve" style="fill:#FFFFFF;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M353.7,251.85c-98.37,0-97.09,98.37-97.09,98.37" />
                        <path class="connect-bottom-Bottom-Shape-Curve" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M159.57,448.78c98.32,0,97.04-98.32,97.04-98.32" />
                        <line class="connect-bottom-Bottom-Shape-Veritcal" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="159.57" y1="350.46" x2="159.57" y2="448.78" />
                        <path class="connect-bottom-TopShape-Curve" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M353.83,9.25c-40.76,0-79.85,7.15-116.09,20.26C106.44,77,12.62,202.77,12.62,350.46" />
                        <line class="connect-bottom-TopShape-Horiz" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="12.62" y1="350.46" x2="353.83" y2="350.46" />
                        <line class="connect-bottom-TopShape-Vert" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="353.83" y1="350.46" x2="353.83" y2="9.25" />

                        <!-- black -->
                        <path class="connect-bottom-OVERLAP-Shape-STROKE-Curve" style="fill:#FFFFFF;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M353.7,251.85c-98.37,0-97.09,98.37-97.09,98.37" />
                        <path class="connect-bottom-Bottom-Shape-Curve" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M159.57,448.78c98.32,0,97.04-98.32,97.04-98.32" />
                        <line class="connect-bottom-Bottom-Shape-Veritcal" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="159.57" y1="350.46" x2="159.57" y2="448.78" />
                        <path class="connect-bottom-TopShape-Curve" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M353.83,9.25c-40.76,0-79.85,7.15-116.09,20.26C106.44,77,12.62,202.77,12.62,350.46" />
                        <line class="connect-bottom-TopShape-Horiz" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="12.62" y1="350.46" x2="353.83" y2="350.46" />
                        <line class="connect-bottom-TopShape-Vert" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="353.83" y1="350.46" x2="353.83" y2="9.25" />


                    </svg>





                    <!-- <a :href="data.bottom.buttonLink" target="_blank"> -->
                    <router-link to="/careers">
                        <div class="btn btn--red-outline">
                            <span>{{ data.bottom.button }}</span>
                        </div>
                    </router-link>
                    <!-- </a> -->


                </div>
                <div class="connect__rise__dots-after">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>

            </div>

            <div class="connect__more-news">

                <div class="connect__more-news__header">
                    <p><span>Latest</span> Stories</p>
                </div>

                <div class="connect__more-news__stories">

                    <!-- <router-link v-for="(story, i) in data.news" :key="story.id" :to="story.slug" append :class="['stories__news-mobile__article', { 'first': i === 0 }]"> -->

                    <router-link v-for="(story, i) in data.news" :key="story.id" :to="`/stories/${story.slug}`" append :class="['stories__news-mobile__article', { 'first': i === 0 }]">

                        <div class="image" :style="'background-image: url(' + story.asset.path + ')'" v-if="story.asset.type == 'image'"></div>
                        <div class="video" v-else>
                            <div class="video-wrap">
                                <video autoplay muted loop playsinline>
                                    <source type="video/mp4" :src="story.asset.path">
                                </video>
                            </div>
                        </div>
                        <div class="caption">
                            <div class="top">
                                <p class="date-numeric">{{ story.date.numeric }}</p>
                                <p class="author">{{ story.author }}</p>
                            </div>
                            <!-- 75 char max -->
                            <p class="title">{{ story.title }}</p>
                        </div>
                    </router-link>
                </div>

                <router-link to="stories">
                    <diamond-button>ALL NEWS</diamond-button>
                </router-link>
            </div>

            <div class="connect__bottom-line">
                <div class="bar bar--right"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>

        </div>
        <site-footer></site-footer>
    </section>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import DiamondButton from '../components/buttons/DiamondButton'
    import Footer from '@/components/Footer'
    import ConnectMixin from '../mixins/ConnectTimeline.js'
    import Meta from '../mixins/Meta.js'
    import 'jquery-ui/ui/widgets/slider.js';

    import gsap, {Power2, Power3} from 'gsap'
    import SplitText from "gsap/SplitText"
    gsap.registerPlugin(SplitText)

    export default {
        mixins: [ConnectMixin, Meta],
        components: {
            DiamondButton,
            'site-footer': Footer
        },
        data() {
            return {
                data: null
            }
        },
        mounted() {
            this.loadData();
        },
        methods: {
            loadData() {
                axios.get(this.$json('/data/connect.json'))

                    .then(response => {
                        this.data = response.data;
                        this.setMetaData(this.data.meta);
                        // console.log(this.data);
                        this.$sectionLoaded();
                        Vue.nextTick(() => {
                            this.initTimeline();
                            this.initSlider();
                            this.revealTop();
                            setTimeout(() => {
                                // in mixin
                                this.tl.play();
                            }, 600);
                        });
                        this.$checkAgeGate(this.data.ageGate);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            revealTop() {
                var topHeadline = gsap.timeline({
                    delay: 1
                });

                topHeadline.from($('.connect__top__copy__logo--mark'), .5, {
                    x: 50,
                    opacity: 0,
                    ease: Power2.easeInOut
                }, .25);

                // topHeadline.from($('.connect__top__copy__header'), .5, { x:50, opacity: 0, ease: Power2.easeInOut }, .5);

                topHeadline.from($('.connect__top__copy__subheading'), .5, {
                    x: 50,
                    letterSpacing: ".6em",
                    opacity: 0,
                    ease: Power2.easeInOut
                }, .5);

                topHeadline.from($('.connect__top__copy__description'), .5, {
                    x: 50,
                    opacity: 0,
                    ease: Power2.easeInOut
                }, .75);

                var mySplitText = new SplitText(".connect__top__copy__header", {
                    type: "lines",
                    linesClass: 'line-split'
                });

                $('.line-split').each(function(i, el) {
                    var curLine = $(el).wrap('<div class="line-split-wrap"></div>');
                })

                var lines = mySplitText.lines; //an array of all the divs that wrap each character
                topHeadline.from(lines, .5, {
                    yPercent: 100,
                    ease: Power3.easeInOut,
                    stagger:0
                }, "-=.75");
            },
            revealRise() {
                var revealRiseTl = gsap.timeline({
                    delay: 1
                });
                revealRiseTl.timeScale(2.7);
                // revealRiseTl.timeScale(1);
                let stagger = 0.3;

                revealRiseTl.fromTo($('.connect-bottom-TopShape-Horiz'), 3, {
                    drawSVG: '100% 100%'
                }, {
                    drawSVG: '0% 100%',
                    ease: Power3.easeInOut
                , stagger: stagger}, 0);
                revealRiseTl.fromTo($('.connect-bottom-TopShape-Vert'), 3, {
                    drawSVG: '0% 0%'
                }, {
                    drawSVG: '0% 100%',
                    ease: Power3.easeInOut
                , stagger: stagger}, 0);

                revealRiseTl.fromTo($('.connect-bottom-OVERLAP-Shape-STROKE-Curve'), 1, {
                    drawSVG: '100% 100%'
                }, {
                    drawSVG: '0% 100%',
                    ease: Power3.easeInOut
                , stagger: stagger}, 1.25);


                revealRiseTl.fromTo($('.connect-bottom-Bottom-Shape-Curve'), 1, {
                    drawSVG: '100% 100%'
                }, {
                    drawSVG: '0% 100%',
                    ease: Power3.easeInOut
                , stagger: stagger}, 1.25);
                revealRiseTl.fromTo($('.connect-bottom-Bottom-Shape-Veritcal'), 1, {
                    drawSVG: '100% 100%'
                }, {
                    drawSVG: '0% 100%',
                    ease: Power3.easeInOut
                , stagger: stagger}, 1.75);

                revealRiseTl.fromTo($('.connect-bottom-TopShape-Curve'), 1, {
                    drawSVG: '0% 0%'
                }, {
                    drawSVG: '0% 100%',
                    ease: Power3.easeInOut
                , stagger: stagger}, 2.25);


                revealRiseTl.fromTo($('.connect-bottom-img'), 1.5, {
                    opacity: 0
                }, {
                    opacity: 1,
                    ease: Power2.easeInOut
                }, 3);

                revealRiseTl.fromTo($('.the-team__text-and-image__description > p'), 1.5, {
                    opacity: 0,
                    x: -50
                }, {
                    opacity: 1,
                    x: 0,
                    ease: Power2.easeInOut
                }, "-=1");
            },
            revealConnectGrid() {
                var revealConnectGridTl = gsap.timeline({
                    delay: 1
                });
                revealConnectGridTl.timeScale(3);
                let stagger = 0.3;

                revealConnectGridTl.from($('.connect__link-grid__cell:not(.design) > svg, p'), .5, {
                    y: 40,
                    opacity: 0,
                    ease: Power2.easeInOut,
                    stagger: stagger
                }, 0.25);
            }
        }
    }
</script>
